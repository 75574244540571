import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';

import {
    FIRST_IMAGE_PARAMS,
    SECOND_IMAGE_PARAMS,
} from './framed-radial-gradient-image/constants';
import { FramedRadialGradientImage } from './framed-radial-gradient-image';
import { SectionInfo } from './section-info';

const SContainer = styled(Container)`
    display: flex;
    position: relative;
    gap: 5rem;

    ${CONSTANTS.MEDIA.max900`
        flex-direction: column;
        gap: 9.375rem;
    `}
`;

const SingleSection = ({
    image,
    imageAlt,
    title,
    firstSection,
    secondSection,
    reversed,
}) => {
    const imageProps = { image, imageAlt };
    const leftContainer = reversed ? (
        <FramedRadialGradientImage
            {...imageProps}
            {...SECOND_IMAGE_PARAMS}
            secondary
        />
    ) : (
        <FramedRadialGradientImage {...imageProps} {...FIRST_IMAGE_PARAMS} />
    );
    const rightContainer = (
        <SectionInfo
            title={title}
            firstSection={firstSection}
            secondSection={secondSection}
        />
    );

    return (
        <SContainer>
            {reversed ? (
                <>
                    {rightContainer}
                    {leftContainer}
                </>
            ) : (
                <>
                    {leftContainer}
                    {rightContainer}
                </>
            )}
        </SContainer>
    );
};

export const GeneralInfoSection = () => {
    const images = useStaticQuery(graphql`
        {
            teamOne: file(relativePath: { eq: "team/team-comics-one.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            teamTwo: file(relativePath: { eq: "team/team-comics-two.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return (
        <>
            <SingleSection
                image={images.teamOne.childImageSharp.gatsbyImageData}
                imageAlt="Marcin Sadowski and Mateusz (Matt) Sadowski Mobile Reality Co-founders and Co-owners"
                title="view-team.whatIsOurStory"
                firstSection="view-team.whatIsOurStoryInfo"
                secondSection="view-team.whatIsOurStoryInfoNext"
            />
            <SingleSection
                image={images.teamTwo.childImageSharp.gatsbyImageData}
                imageAlt="Team Discussion"
                reversed={true}
                title="view-team.ourServices"
                firstSection="view-team.ourServicesInfo"
                secondSection="view-team.ourServicesInfoNext"
            />
        </>
    );
};
